import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/vercel/path0/node_modules/gatsby-theme-carbon/src/templates/Default.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h4>{`OHS 5×2\\@90%`}</h4>
    <p>{`then,`}</p>
    <p>{`4 Rounds for time of:`}</p>
    <p>{`12-Snatch Grip Deadlifts (95/65)`}</p>
    <p>{`9-Power Snatch`}</p>
    <p>{`6-Hang Squat Snatch`}</p>
    <h4><strong parentName="h4">{`*`}{`CrossFit the Ville’s Barbell Club will be starting today!  If you’d like to improve your Clean & Jerk and Snatch these classes are for you.***`}</strong>{` ***`}</h4>
    <h4><strong parentName="h4">{`*`}{`*`}{`*`}{`This first class is free to all so come give it a try!  We’ll be working on snatch and assisting exercises today, focusing strictly on technique and mobility.`}{`*`}{`*`}{`*`}</strong></h4>
    <h4><strong parentName="h4">{`Classes will be an hour and a half each and offered Saturday’s (11:15-12:45) and Sunday’s (1:45-3:00) each weekend after our CrossFit classes. You can pay per class `}<em parentName="strong">{`(\\$15 for members, \\$20 for non members)`}</em>{` or pay upfront for a discounted rate for the entire online program plus all classes `}<em parentName="strong">{`(\\$120 for members, \\$200 for couples)`}</em>{`.  Email Daniel for more information or simply show up to class.`}</strong></h4>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      